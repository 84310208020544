// Stack.js
import React from "react";
import { Row, Col, Nav, Tab, Container } from "react-bootstrap";
import "./Stack.css";
import { ProjStackCard } from "./ProjStackCard";
import { items } from "./StackData";

export const Stack = () => {
  const categories = ["mern", "react", "nextJs"];

  const renderItems = (filterCategory) => {
    return items
      .filter(item => item.category === filterCategory)
      .map(item => (
        <Col key={item.id} sm={4} className="mb-4">
          <ProjStackCard title={item.title} stack={item.stack} image={item.image} link={item.link}/>
        </Col>
      ));
  };

  return (
    <Container className="stack-container" id="Stack">
      <Tab.Container defaultActiveKey={categories[0]}>
        <Row className="justify-content-center stack-row stack-container">
          <Col md={12} className="text-center">
            <Nav variant="pills" className="justify-content-center mb-5">
              {categories.map((category) => (
                <Nav.Item key={category}>
                  <Nav.Link eventKey={category} className="btn-theme btn-small">
                    {category}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Col>

          <Col md={12}>
            <Tab.Content>
              {categories.map((category) => (
                <Tab.Pane key={category} eventKey={category}>
                  <Row>
                    {renderItems(category)}
                  </Row>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};
