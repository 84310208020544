import React from "react";
import { TOTAL_SCREENS } from "../utilities/commonUtils";

export const PortfolioContainer = () => {
  const mapAllScreens = () => {
    return TOTAL_SCREENS.map((screen, index) =>
      screen.component ? (
        <screen.component
          screenName={screen.screen_name || `screen-${index}`}
          key={screen.screen_name || `screen-${index}`}
          id={screen.screen_name || `screen-${index}`}
        />
      ) : (
        <div key={screen.screen_name || `screen-${index}`}></div>
      )
    );
  };
  return <>{mapAllScreens()}</>;
};