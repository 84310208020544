import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import "./StchToggle.css";

const StchToggle = () => {
  const [checked, setChecked] = useState(false);
  const { i18n } = useTranslation();

  const handleChange = () => {
    setChecked(!checked);
    i18n.changeLanguage(checked ? 'ro' : 'en');
  };

  return (
    <div className="stch-content">
      <input type="checkbox" id="stch-btn" checked={checked} onChange={handleChange} />
      <label htmlFor="stch-btn">
        <span className="stch-track">
          <span className="stch-txt"></span>
        </span>
        <span className="stch-thumb">|||</span>
      </label>
    </div>
  );
};

export default StchToggle;
