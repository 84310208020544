import "./App.css";
import React from "react";
import { PortfolioContainer } from "./PortfolioContainer/PortfolioContainer";
import * as Sentry from '@sentry/react'

const App = () => {
  return (
    <div className="App">
      <PortfolioContainer />
    </div>
  );
};

export default Sentry.withProfiler(App)
