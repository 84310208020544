import React, { useState } from "react";
import imgBack from "../../../src/images/mailz.webp";
import load1 from "../../../src/images/load2.gif";
import { Form, Button } from "react-bootstrap";
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

export const ContactForm = ({ handleInput, formData }) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [banner, setBanner] = useState("");
  const [bool, setBool] = useState(false);

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : t("ContactMe.validation.nameRequired");
    tempErrors.email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData.email) ? "" : t("ContactMe.validation.emailInvalid");
    tempErrors.message = formData.message.length > 20 ? "" : t("ContactMe.validation.messageTooShort");
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setBanner(t('ContactMe.banner.validationFailed'));
      return;
    }

    setBool(true);

    emailjs.sendForm(serviceId, templateId, e.target, userId)
    .then(() => {
        setBanner(t('ContactMe.banner.success'));
        e.target.reset(); 
    }, () => {
        setBanner(t('ContactMe.banner.error'));
    })
    .finally(() => {
      setBool(false);
    });
  };
    
  return (
    <div className="back-form">
      <div className="img-back">
        <h1>{t("ContactMe.sendYourEmail")}</h1>
        <img src={imgBack} alt="Contact form background" />
      </div>

      <Form onSubmit={sendEmail}> 
        <p>{banner}</p>
        
        <Form.Group controlId="name">
          <Form.Label>{t("ContactMe.name")}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            onChange={handleInput}
            defaultValue={formData.name}
            className={errors.name ? "input-error" : "input"}
          />
          {errors.name && <div className="error">{errors.name}</div>}
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>{t("ContactMe.email")}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            onChange={handleInput}
            defaultValue={formData.email}
            className={errors.email ? "input-error" : "input"}
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </Form.Group>

        <Form.Group controlId="message">
          <Form.Label>{t("ContactMe.message")}</Form.Label>
          <Form.Control
            as="textarea"
            name="message" 
            onChange={handleInput}
            defaultValue={formData.message}
            className={errors.message ? "input-error" : "input"}
          />
          {errors.message && <div className="error">{errors.message}</div>}
        </Form.Group>

        <div className="send-btn">
          <Button type="submit" className="button">
            {t("ContactMe.send")}
            <i className="fa fa-paper-plane" />
            {bool && (
              <b className="load">
                <img src={load1} alt="Loading spinner" />
              </b>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};
