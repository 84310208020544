import React from "react";
import { useTranslation } from 'react-i18next';
import { EducationHeading } from "../ResumeHeading/EducationHeading";

const educationDetails = [
  {
    key: "UdemyPHP",
  },
  {
    key: "UdemyBootstrap",
  },
  {
    key: "UdemyMaterialUI",
  },
  {
    key: "SkillBrain",
  },
  {
    key: "University",
  },
];

export const Education = () => {
  const { t } = useTranslation();
  
  return (
    <div className="resume-screen-container" key="education">
      {educationDetails.map((education, index) => (
        <EducationHeading
          key={index}
          title={t(`EducationDetails.${education.key}.title`)}
          subHeading={t(`EducationDetails.${education.key}.subHeading`)}
          fromDate={t(`EducationDetails.${education.key}.fromDate`)}
          toDate={t(`EducationDetails.${education.key}.toDate`)}
        />
      ))}
    </div>
  );
};
