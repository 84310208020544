import "./Skills.css";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Animations from "../../utilities/Animations";
import { ScreenHeading } from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollServices from "../../utilities/ScrollService";

const skillsData = [
  { name: "HTML5", image: "html5.png", alt: "HTML5 logo" },
  { name: "CSS3", image: "css3.png", alt: "CSS3 logo" },
  { name: "JavaScript", image: "javascript.png", alt: "JavaScript logo" },
  { name: "jQuery", image: "jquery.png", alt: "jQuery logo" },
  { name: "Bootstrap", image: "bootstrap.png", alt: "Bootstrap logo" },
  { name: "Material UI", image: "MUI.png", alt: "Mui logo" },
  { name: "React", image: "react.png", alt: "React logo" },
  { name: "Redux", image: "redux.png", alt: "Redux logo" },
  { name: "MongoDB", image: "mongo.png", alt: "MongoDB logo" },
  { name: "PHP", image: "php.png", alt: "PHP logo" },
  { name: "GSAP", image: "gsap.webp", alt: "GSAP logo" },
  { name: "ThreeJs", image: "threejs.webp", alt: "ThreeJs logo" },
];

const toolsData = [
  { name: "Git", image: "git.png", alt: "Git logo" },
  { name: "Npm", image: "npm.png", alt: "Npm logo" },
  { name: "Command Line", image: "command.png", alt: "Command Line logo" },
  { name: "VS Code", image: "vs-code.png", alt: "VS Code logo" },
];

export const Skills = ({ id }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("skills");

  useEffect(() => {
    const fadeInHandler = (screen) => {
      if (screen.fadeInScreen !== id) return;
      Animations.animations.fadeInScreen(id);
    };

    const fadeInSubscription = ScrollServices.currentScreenFadeIn.subscribe(
      fadeInHandler
    );

    return () => fadeInSubscription.unsubscribe();
  }, [id]);

  const renderCards = (data) =>
    data.map((item, index) => (
      <li key={`${item.name}-${index}`}>
        <div className="skill-card">
          <div className="tooltip">{item.name}</div>
          <div className="card-icon">
            <img src={require(`./images/${item.image}`)} alt={item.alt} />
          </div>
        </div>
      </li>
    ));

  return (
    <div className="resume-container screen-container fade-in" id={id || ""}>
      <div className="resume-content">
        <ScreenHeading
          title={t("SkillsDetails.title")}
          subHeading={t("SkillsDetails.subHeading")}
        />
        <section className="skills" id="skills">
          <div className="skills-content section-content">
            <h2 className="skills-primary-text">{t("SkillsDetails.heading")}</h2>
            <p className="section-text">{t("SkillsDetails.description")}</p>

            <div className="skills-toggle">
              <button
                className={`toggle-btn ${activeTab === "skills" ? "active" : ""}`}
                onClick={() => setActiveTab("skills")}
              >
                Skills
              </button>
              <button
                className={`toggle-btn ${activeTab === "tools" ? "active" : ""}`}
                onClick={() => setActiveTab("tools")}
              >
                Tools
              </button>
            </div>
          </div>

          <div className="skills-box">
            {activeTab === "skills" && <ul className="skills-list">{renderCards(skillsData)}</ul>}
            {activeTab === "tools" && <ul className="tools-list">{renderCards(toolsData)}</ul>}
          </div>
        </section>
      </div>
    </div>
  );
};
