import React from "react";
import "./Stack.css";

export const ProjStackCard = ({ title, stack, image, link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className="stack-card-link">
      <div className="stack-card-wrapper">
        <div className="stack-card">
          <div className="img-stack-div">
            <img src={image} alt={title} className="stack-card-image" />
          </div>
          <h3 className="stack-card-title">{title}</h3>
          <p className="stack-powered">{stack}</p>
        </div>
      </div>
    </a>
  );
};