import adviceSlipImg from "./img/adviceslip.webp";
import aimGameImg from "./img/aimgame.webp";
import jokeappImg from "./img/jokeapp.webp";
import mouvieappImg from "./img/mouvieapp.webp";
import picsearchImg from "./img/picsearch.webp";
import travel from "./img/travel.webp";
import weatherImg from "./img/weather.webp";
import MernAuth from "./img/MernAuth.webp";
import shop from "./img/shop.webp";
import syntaxseeker from "./img/syntaxseeker.webp";
import iphone15clone from "./img/iphone-15clone.webp";
import travelAppNextJs from "./img/travelAppNext.webp";

export const items = [
  { id: 1, category: "react", title: "Iphone 15 clone", stack: "react, gsap, three.js", image: iphone15clone, link: "https://iphone15-cl.netlify.app/" },
  { id: 2, category: "react", title: "Advice SLip", stack: "react, rapidApi", image: adviceSlipImg, link: "https://adviceslip123.netlify.app/" },
  { id: 3, category: "react", title: "Aim Game", stack: "react", image: aimGameImg, link: "https://aim-game-app.netlify.app/" },
  { id: 4, category: "react", title: "Joke App", stack: "react, api`s", image: jokeappImg, link: "https://jokeapp123.netlify.app/" },
  { id: 5, category: "react", title: "MovieLand", stack: "react, api", image: mouvieappImg, link: "https://movie-api-app123.netlify.app/" },
  { id: 6, category: "react", title: "Picture Search", stack: "react, picsum api", image: picsearchImg, link: "https://pic-search123.netlify.app/" },
  { id: 7, category: "mern", title: "Travel Blog", stack: "mern stack, material-ui", image: travel, link: "https://travel-blog-app-frontend.vercel.app/" },
  { id: 8, category: "react", title: "Weather App", stack: "react, api", image: weatherImg, link: "https://react-weather-app1234.netlify.app/" },
  { id: 9, category: "mern", title: "Complete Auth", stack: "mern stack, redux", image: MernAuth, link: "https://syntaxseeker-auth.vercel.app/" },
  { id: 10, category: "mern", title: "Shop App", stack: "mern stack, redux", image: shop, link: "https://shop.syntaxseeker.com/" },
  { id: 11, category: "mern", title: "SyntaxSeeker", stack: "mern stack, redux", image: syntaxseeker, link: "https://syntaxseeker.com/" },
  { id: 12, category: "nextJs", title: "Travel App", stack: "nextjs, gsap", image: travelAppNextJs, link: "https://travel-app.andrei-design.ro/" },
];