import "../Resume.css";
import React from "react";
import { useTranslation } from 'react-i18next';
import ResumeHeading from "../ResumeHeading/ResumeHeading";

export const Interests = () => {
  const { t } = useTranslation();

  const interests = [
    "Travelling",
    "Music",
    "Sport",
    "Cars",
  ];

  return (
    <div className="resume-screen-container no-full" key="interests">
      {interests.map((interest, index) => (
        <ResumeHeading
          key={index}
          heading={t(`InterestsDetails.${interest}.heading`)}
          description={t(`InterestsDetails.${interest}.description`)}
        />
      ))}
    </div>
  );
};
