import "../Resume.css";
import React from "react";

const programmingSkillsDetails = [
  { skill: "HTML", ratingPercentage: 88 },
  { skill: "CSS", ratingPercentage: 88 },
  { skill: "JavaScript", ratingPercentage: 85 },
  { skill: "React JS", ratingPercentage: 80 },
  { skill: "Material UI", ratingPercentage: 75 },
  { skill: "Bootstrap", ratingPercentage: 80 },
  { skill: "Redux", ratingPercentage: 65 },
  { skill: "NodeJS", ratingPercentage: 85 },
  { skill: "MERN", ratingPercentage: 70 },
  { skill: "Tailwind", ratingPercentage: 65 },
];

export const ProgrammingSkills = () => {
  return (
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>
  );
};
