import React from "react";
import { useTranslation } from 'react-i18next';
import ResumeHeading from "../ResumeHeading/ResumeHeading";

const workExperienceDetails = [
  { key: "Aplast" },
  { key: "BinarCode" },
];

export const WorkExperience = () => {
  const { t } = useTranslation();

  return (
    <div className="resume-screen-container work-experience" key="workExperience">
      {workExperienceDetails.map((workExperience, index) => (
        <ResumeHeading
          key={index}
          heading={t(`WorkExperienceDetails.${workExperience.key}.heading`)}
          subHeading={t(`WorkExperienceDetails.${workExperience.key}.subHeading`)}
          description={t(`WorkExperienceDetails.${workExperience.key}.description`)}
          fromDate={t(`WorkExperienceDetails.${workExperience.key}.fromDate`)}
          toDate={t(`WorkExperienceDetails.${workExperience.key}.toDate`)}
        />
      ))}
    </div>
  );
};
