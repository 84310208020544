import ".././Resume.css";
import React from "react";

const ResumeHeading = (props) => {
  return (
    <div className="resume-heading">
      <div className="resume-main-heading">
        <span>{props.heading ? props.heading : ""}</span>
        {props.projectLink && (
          <a
            className="resume-heading-link"
            href={props.projectLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          </a>
        )}
        {props.fromDate && props.toDate ? (
          <div className="heading-date">
            {props.fromDate + "-" + props.toDate}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="resume-sub-heading">
        <span>{props.subHeading ? props.subHeading : ""}</span>
      </div>
      <div className="resume-heading-description">
        <span>{props.description ? props.description : ""}</span>
      </div>
    </div>
  );
};

export default ResumeHeading;
