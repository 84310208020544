import branding from "./images/branding.webp";
import cafenea from "./images/cafenea.webp";
import pasta from "./images/pasta.webp";
import clinica from "./images/clinica.webp";
import restaurant from "./images/restaurant.webp";
import redgym from "./images/redgym.webp";
import servis from "./images/servis.webp";
import nunta from "./images/nunta.webp";
import trainer from "./images/trainer.webp";
import fotograf1 from "./images/fotograf1.webp";
import fotograf2 from "./images/fotograf2.webp";
import fotograf3 from "./images/fotograf3.webp";
import fotograf4 from "./images/fotograf4.webp";
import fotograf5 from "./images/fotograf5.webp";
import fotograf6 from "./images/fotograf6.webp";

const works = (t) => [
  {
    id: "1",
    img: branding,
    title: t("Projects.brandingTitle"),
    description: t("Projects.brandingDescription"),
    techStack: ["Html", "Css"],
    link: "https://branding.andrei-design.ro/",
  },
  {
    id: "2",
    img: cafenea,
    title: t("Projects.cafeneaTitle"),
    description: t("Projects.cafeneaDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://cafenea.andrei-design.ro/",
  },
  {
    id: "3",
    img: pasta,
    title: t("Projects.pastaTitle"),
    description: t("Projects.pastaDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://pasta.andrei-design.ro/",
  },
  {
    id: "6",
    img: clinica,
    title: t("Projects.clinicaTitle"),
    description: t("Projects.clinicaDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://clinic.andrei-design.ro/",
  },
  {
    id: "8",
    img: restaurant,
    title: t("Projects.restaurantTitle"),
    description: t("Projects.restaurantDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://restaurant.andrei-design.ro/",
  },
  {
    id: "9",
    img: redgym,
    title: t("Projects.redgymTitle"),
    description: t("Projects.redgymDescription"),
    techStack: ["React"],
    link: "https://gym.andrei-design.ro/",
  },
  {
    id: "10",
    img: servis,
    title: t("Projects.servisTitle"),
    description: t("Projects.servisDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://servis.andrei-design.ro/",
  },
  {
    id: "12",
    img: nunta,
    title: t("Projects.nuntaTitle"),
    description: t("Projects.nuntaDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://nunta.andrei-design.ro/",
  },
  {
    id: "13",
    img: trainer,
    title: t("Projects.trainerTitle"),
    description: t("Projects.trainerDescription"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://trainer.andrei-design.ro/",
  },
  {
    id: "5",
    img: fotograf1,
    title: t("Projects.fotograf1Title"),
    description: t("Projects.fotograf1Description"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://fotograf2.andrei-design.ro/",
  },
  {
    id: "14",
    img: fotograf2,
    title: t("Projects.fotograf2Title"),
    description: t("Projects.fotograf2Description"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://fotograf4.andrei-design.ro",
  },
  {
    id: "15",
    img: fotograf3,
    title: t("Projects.fotograf3Title"),
    description: t("Projects.fotograf3Description"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://fotograf3.andrei-design.ro/",
  },
  {
    id: "16",
    img: fotograf4,
    title: t("Projects.fotograf4Title"),
    description: t("Projects.fotograf4Description"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://fotograf6.andrei-design.ro/",
  },
  {
    id: "17",
    img: fotograf5,
    title: t("Projects.fotograf5Title"),
    description: t("Projects.fotograf5Description"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://fotograf5.andrei-design.ro/",
  },
  {
    id: "18",
    img: fotograf6,
    title: t("Projects.fotograf6Title"),
    description: t("Projects.fotograf6Description"),
    techStack: ["Html", "Css", "Javascript"],
    link: "https://fotograf1.andrei-design.ro/",
  },
];

export default works;
