import ".././Resume.css";
import React from "react";

export const EducationHeading = (props) => {
  return (
    <div className="resume-heading">
      <div className="resume-main-heading">
        <span>{props.title ? props.title : ""}</span>
        {props.fromDate && props.toDate ? (
          <div className="heading-date">
            {props.fromDate + "-" + props.toDate}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="resume-sub-heading">
        <span>{props.subHeading ? props.subHeading : ""}</span>
      </div>
      <div className="resume-heading-description">
        <span>{props.description ? props.description : ""}</span>
      </div>
    </div>
  )
}
