import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Footer.css";

const Footer = (props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer" id={props.id || ""}>
      <Container>
        <Row>
          <Col className="text-center">
            {t("Footer.copyright", { year: currentYear })}
            <span className="footer-link">{t("Footer.builtBy")}</span>
          </Col>
        </Row>
      </Container>
      {isVisible && (
        <div className="scroll-container">
          <Button
            className="btn-scroll"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <i className="fa fa-arrow-up"></i>
          </Button>
        </div>
      )}
    </footer>
  );
};

export default Footer;
