import React from "react";
import { useTranslation } from 'react-i18next';
import Animations from "../../utilities/Animations";
import { ScreenHeading } from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollServices from "../../utilities/ScrollService";
import "./AboutMe.css";

export const AboutMe = (props) => {
  const { t } = useTranslation();

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  ScrollServices.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTANTS = {
    title: t("AboutMeDetails.title"),
    subtitle: t("AboutMeDetails.subtitle"),
    description: t("AboutMeDetails.description"),
    highlights: {
      bullets: [
        t("AboutMeDetails.highlights.bullets.0"),
        t("AboutMeDetails.highlights.bullets.1"),
        t("AboutMeDetails.highlights.bullets.2"),
      ],
      heading: t("AboutMeDetails.highlights.heading"),
    },
  };

  const renderHighlight = () => {
    return SCREEN_CONSTANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-me-parent">
        <ScreenHeading title={t("AboutMeDetails.screenHeading.title")} subHeading={t("AboutMeDetails.screenHeading.subHeading")} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <div className="highlight-heading">
              <span className="about-me-title">{SCREEN_CONSTANTS.title}</span>
              <br />
              <span className="about-me-title">{SCREEN_CONSTANTS.subtitle}</span>
            </div>
            <span className="about-me-description">
              {SCREEN_CONSTANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="hire-me-btn">
              <button
                className="btn primary-btn"
                onClick={() => ScrollServices.scrollHandler.scrollToHireMe()}
              >
                {t("AboutMeDetails.hireMeButton")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
