import "./ContactMe.css";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faFacebookSquare,
  faInstagram,
  faGithubSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import Animations from "../../utilities/Animations";
import { ScreenHeading } from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollServices from "../../utilities/ScrollService";
import { ContactForm } from "./ContactForm";
import { AnimationForContactMe } from "./ContactMeAnimations";

export default function ContactMe(props) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  ScrollServices.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <Container id="ContactMe">
        <ScreenHeading
          subHeading={t("ContactMe.subHeading")}
          title={t("ContactMe.title")}
        />
        <div className="central-form">
          <Row>
            <Col>
              <h2>
                <AnimationForContactMe />
              </h2>
              {/* <a
                href="https://www.facebook.com/profile.php?id=100010192758193"
                aria-label="Facebook Profile"
              >
                <FontAwesomeIcon
                  className="icon icon-link"
                  icon={faFacebookSquare}
                />
              </a> */}
              <a
                href="https://github.com/deiu25"
                aria-label="Github Profile"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="icon icon-link"
                  icon={faGithubSquare}
                />
              </a>
              <a
                href="https://www.instagram.com/andrei.designn/"
                aria-label="Instagram Profile"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="icon icon-link"
                  icon={faInstagram}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/andrei-badulescu-55b993240"
                aria-label="LinkedIn Profile"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon className="icon icon-link" icon={faLinkedin} />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <ContactForm formData={formData} handleInput={handleInput} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
