import "./Resume.css";
import React, { useEffect, useState, useCallback } from "react";
import Animations from "../../utilities/Animations";
import { ScreenHeading } from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollServices from "../../utilities/ScrollService";
import { FaUserGraduate, FaLaptopCode } from "react-icons/fa";
import { MdOutlineWorkHistory, MdOutlineInterests } from "react-icons/md";
import { Education } from "./ResumeElements/Education";
import { WorkExperience } from "./ResumeElements/WorkExperience";
import { ProgrammingSkills } from "./ResumeElements/ProgrammingSkill";
import { Interests } from "./ResumeElements/Interests";
import { useTranslation } from "react-i18next";

export const Resume = (props) => {
  const { t } = useTranslation();

  const resumeBullets = [
    { label: t("ResumeDetails.education"), iconName: <FaUserGraduate /> },
    { label: t("ResumeDetails.workHistory"), iconName: <MdOutlineWorkHistory /> },
    { label: t("ResumeDetails.programmingSkills"), iconName: <FaLaptopCode /> },
    { label: t("ResumeDetails.interests"), iconName: <MdOutlineInterests /> },
  ];

  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  const fadeInScreenHandler = useCallback(
    (screen) => {
      if (screen.fadeInScreen !== props.id) return;

      Animations.animations.fadeInScreen(props.id);
    },
    [props.id]
  );

  const fadeInSubscription =
    ScrollServices.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const resumeDetailElements = [
    <Education key="education" />,
    <WorkExperience key="work-experience" />,
    <ProgrammingSkills key="programming-skills" />,
    <Interests key="interests" />,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = (selectedBulletIndex + 1) % resumeBullets.length;
      handleCarousal(newIndex);
    }, 10000);

    return () => {
      clearInterval(interval);
      fadeInSubscription.unsubscribe();
    };
  },);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={t("ResumeDetails.title")} subHeading={t("ResumeDetails.subHeading")} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">
                {resumeBullets.map((bullet, index) => (
                  <div
                    onClick={() => handleCarousal(index)}
                    className={
                      index === selectedBulletIndex
                        ? "bullet selected-bullet"
                        : "bullet"
                    }
                    key={index}
                  >
                    <span className="icon-always-white">{bullet.iconName}</span>
                    <span className="bullet-label">{bullet.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="resume-bullet-details">
            <div
              style={carousalOffsetStyle.style}
              className="resume-details-carousal"
            >
              {resumeDetailElements.map((ResumeDetail) => ResumeDetail)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
