import React from "react";
import "./Footer.css";
import shapeBg from "../../../assets/Home/shape-bg.svg";

export const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-parent">
        <img src={shapeBg} alt="shape" loading="eager" />
      </div>
    </div>
  );
};
