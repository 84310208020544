import gsap from "gsap";
import { useRef, useEffect } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import works from "./WorkConstants";
import { ScreenHeading } from "../../utilities/ScreenHeading/ScreenHeading";
import { useTranslation } from "react-i18next";
import "./Projects.css";

gsap.registerPlugin(ScrollTrigger);

export const Projects = () => {
  const { t } = useTranslation();
  const wrapper = useRef(null);
  const pinnerRef = useRef(null);

  useEffect(() => {
    const animateProjects = () => {
      const screenSize = window.innerWidth > 767 ? "desktop" : "mobile";
      gsap.to(wrapper.current.children, {
        scrollTrigger: {
          trigger: pinnerRef.current,
          start: "top top",
          end: () => `+=${wrapper.current.clientHeight * (wrapper.current.children.length - 1)}`,
          pin: true,
          scrub: 2,
        },
        y: (index) => `-${index * 100}%`,
        stagger: screenSize === "desktop" ? 0.5 : 0.3,
      });
    };

    const mm = gsap.matchMedia();
    mm.add("(min-width: 0px)", animateProjects);

    return () => mm.revert();
  }, []);

  const projects = works(t);

  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container
      fluid
      ref={pinnerRef}
      id="Portfolio"
      className="d-flex flex-column align-items-center justify-content-center mrn-container-main"
    >
      <ScreenHeading
        title={t("Projects.title")}
        subHeading={t("Projects.subHeading")}
      />
      <Container
        fluid
        ref={wrapper}
        className="d-flex flex-column align-items-center justify-content-center position-relative mb-5 mrn-wrapper"
      >
        {projects.map((work, index) => (
          <Row
            key={work.id}
            className="d-flex flex-wrap-reverse align-items-center justify-content-center position-absolute w-100 p-4 mrn-row"
            style={{ top: `${index * 100}%` }}
          >
            <Col md={6} className="d-flex flex-column justify-content-center p-4 text-center text-md-left">
              <h3 className="font-weight-semibold">{work.title}</h3>
              <p>{work.description}</p>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
                {work.techStack.map((item, ind) => (
                  <span key={ind} className="pr-3 py-1 font-weight-bold">{item}</span>
                ))}
              </div>
              <div className="d-flex flex-wrap justify-content-center justify-content-md-start mt-3 proj-buttons-align">
                {work.link !== "#" && (
                  <Button
                    onClick={() => window.open(work.link, "_blank")}
                    className="mr-2 primary-btn"
                    aria-label={`View project ${work.title}`}
                  >
                    {t("Projects.SeeButton")}
                  </Button>
                )}
                <Button
                  onClick={() => scrollToSection("Resume")}
                  className="mr-2 upDown-btn proj-buttonn"
                  aria-label="Scroll To Resume"
                >
                  <i className="bi bi-chevron-up"></i>
                </Button>
                <Button
                  onClick={() => scrollToSection("Stack")}
                  className="upDown-btn"
                  aria-label="Scroll To Contact"
                >
                  <i className="bi bi-chevron-down"></i>
                </Button>
              </div>
            </Col>
            <Col md={6} className="d-flex align-items-center justify-content-center">
              <Image
                src={work.img}
                alt={work.title}
                className="project-image img-fluid rounded"
                loading="lazy"
              />
            </Col>
          </Row>
        ))}
      </Container>
    </Container>
  );
};
