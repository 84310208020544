import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';

export const AnimationForContactMe = () => {
  const { t } = useTranslation();
  const [wordIndex, setWordIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [text, setText] = useState("");
  const [reverse, setReverse] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const words = useMemo(() => t('ContactMe.animationWords', { returnObjects: true }), [t]);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (reverse) {
        if (letterIndex === 0) {
          setReverse(false);
          setWordIndex((wordIndex + 1) % words.length);
          setLetterIndex(0);
          setText("");
          setWaiting(false);
        } else {
          setLetterIndex(letterIndex - 1);
          setText(text.substring(0, text.length - 1));
        }
      } else {
        if (waiting) {
          return;
        } else if (letterIndex === words[wordIndex].length) {
          setWaiting(true);
        } else {
          setLetterIndex(letterIndex + 1);
          setText(text + words[wordIndex][letterIndex]);
        }
      }
    }, 100);
    if (waiting) {
      setTimeout(() => {
        setWaiting(false);
        setReverse(true);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [letterIndex, reverse, text, waiting, wordIndex, words]);

  return (
    <div style={{ display: "inline-block" }}>
      <span>{text}</span>
    </div>
  );
};
