import { AboutMe } from "../PortfolioContainer/AboutMe/AboutMe";
import ContactMe from "../PortfolioContainer/ContactMe/ContactMe";
import { Home } from "../PortfolioContainer/Home/Home";
import { Resume } from "../PortfolioContainer/Resume/Resume";
import { Projects } from "../PortfolioContainer/Projects/Projects";
import Footer from "../PortfolioContainer/Footer/Footer";
import { Skills } from "../PortfolioContainer/Skills/Skills";
import { Stack } from "../PortfolioContainer/Stack/Stack";

export const TOTAL_SCREENS = [
  {
    screen_name: "Home",
    component: Home,
  },
  {
    screen_name: "AboutMe",
    component: AboutMe,
  },
  {
    screen_name: "Skills",
    component: Skills,
  },
  {
    screen_name: "Resume",
    component: Resume,
  },
  {
    screen_name: "Portfolio",
    component: Projects, 
  },
  {
    screen_name: "Stack",
    component: Stack, 
  },
  {
    screen_name: "Contact",
    component: ContactMe,
  },
  {
    component: Footer,
  }
];

export const GET_SCREEN_INDEX = (screen_name) => {
  if (!screen_name) return -1;
  return TOTAL_SCREENS.findIndex(
    (screen) => screen.screen_name === screen_name
  );
};