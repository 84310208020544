import React from "react";
import { useTranslation } from 'react-i18next';
import "./Profile.css";
import TextAnimation from "./Animation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faFacebookSquare,
  faInstagram,
  faGithubSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import ScrollServices from "../../../utilities/ScrollService";
import profilePhoto from "../../../assets/Home/profilephoto.webp";

export default function Profile() {
  const { t } = useTranslation();

  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a href="https://github.com/deiu25" aria-label="GitHub Profile">
                <FontAwesomeIcon
                  className="icon icon-link"
                  icon={faGithubSquare}
                />
              </a>
              <a
                href="https://www.instagram.com/andrei.designn/"
                aria-label="Instagram Profile"
                target="_blank" rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="icon icon-link"
                  icon={faInstagram}
                />
              </a>
              <a href="https://www.linkedin.com/in/andrei-badulescu-55b993240" aria-label="LinkedIn Profile">
                <FontAwesomeIcon className="icon icon-link" icon={faLinkedin} />
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {t('Hello')} <span className="highlighted-text">Andrei</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              <h1>
                <TextAnimation />
              </h1>
              <span className="profile-role-tagline mb-5">
                {t('Knack')}
              </span>
              <br />
              <p className="profile-role-tagline">
                {t('Design')}
              </p>
            </span>
          </div>
          <div className="profile-options">
            <button
              className="btn primary-btn"
              onClick={() => ScrollServices.scrollHandler.scrollToHireMe()}
            >
              {t('HireMe')}
            </button>
              <button
              onClick={() => ScrollServices.scrollHandler.scrollToHireMe()}
              className="btn highlighted-btn">{t('GetResume')}</button>
          </div>
        </div>
        <div className="profile-picture">
          <img
            src={profilePhoto}
            alt="Profile"
            className="profile-picture-background"
          />
        </div>
      </div>
    </div>
  );
}
